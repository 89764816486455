import React, { useEffect, useState } from "react";
import { RiArrowUpLine } from "react-icons/ri";
import axios from "axios";

export default function PriceChangeBadge({ symbol, hardcapToken, poolId }) {
  const [priceChangePercent, setPriceChangePercent] = useState("0.00");

  useEffect(() => {
    const fetchPriceChange = async () => {
      try {
        const response = await axios.get(
          `https://api.safubit.com/api/ohlc/priceChange/${symbol}-${hardcapToken}?extra_key=SAFUPUMP-${poolId}&interval=24h`
        );
        setPriceChangePercent(response.data.price_change_percent);
      } catch (error) {
        console.error("Error fetching price change:", error);
      }
    };

    fetchPriceChange();
  }, [symbol, hardcapToken, poolId]);

  return (
    <div className="bg-blue-ribbon-500 text-white py-1 text-xs px-2 flex items-center justify-end rounded-lg">
      +{Number(priceChangePercent).toFixed(2)}% <RiArrowUpLine />
    </div>
  );
}
