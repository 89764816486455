import dynamic from "next/dynamic";
import { Banner } from "@/components/Banner";
import {
  RiArrowLeftLine,
  RiArrowRightLine,
  RiArrowUpLine,
  RiDiscussLine,
  RiGlobalLine,
  RiRefreshLine,
  RiTelegramLine,
  RiTimeLine,
  RiTwitterXLine,
} from "react-icons/ri";
import Image from "next/image";
import Link from "next/link";
import { createClient } from "@supabase/supabase-js";
import { useEffect, useState } from "react";
import NetworkIcon from "@/components/NetworkIcon";
import { truncate } from "@/libs/WebooLib";
import { getChainIdByName, readmore } from "@/utils";
import { Listbox } from "@headlessui/react";
import PriceChangeBadge from "@/components/PriceChangeBadge";
import { useWeb3Context } from "@/hooks/Web3Context";
import { Button, Tooltip, Whisper } from "rsuite";
import "rsuite/Tooltip/styles/index.css";

const supabaseUrl = process.env.NEXT_PUBLIC_SUPABASE_URL;
const supabaseKey = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

const Header = dynamic(
  () => import("@/components/Header").then((mod) => mod.Header),
  {
    ssr: false,
  }
);
const Footer = dynamic(
  () => import("@/components/Footer").then((mod) => mod.Footer),
  {
    ssr: false,
  }
);
const HoverBorderGradient = dynamic(
  () =>
    import("@/components/ui/hover-border-gradient").then(
      (mod) => mod.HoverBorderGradient
    ),
  {
    ssr: false,
  }
);

export default function Home() {
  const { address, chainId } = useWeb3Context();
  const [pools, setPools] = useState([]);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({
    marketcap: "",
    project_name: "",
    network: "",
    sortBy: "",
    project_is_kyc: false,
    project_is_audit: false,
    project_is_doxx: false,
    project_is_cex: false,
    is_renounce: false,
    is_pro: false,
    all: false,
  });

  useEffect(() => {
    const fetchPools = async () => {
      let query = supabase.from("pool").select(`
        *,
        thread (
          id
        )
      `);

      if (filter.network && !filter.all) {
        query = query.eq("network", filter.network);
      }

      // Search by project name, symbol, or contract address
      if (filter.project_name) {
        query = query.ilike("project_name", `%${filter.project_name}%`);
      }

      if (filter.project_is_kyc && !filter.all) {
        query = query.eq("project_is_kyc", true);
      }

      if (filter.project_is_audit && !filter.all) {
        query = query.eq("project_is_audit", true);
      }

      if (filter.project_is_doxx && !filter.all) {
        query = query.eq("project_is_doxx", true);
      }

      if (filter.project_is_cex && !filter.all) {
        query = query.eq("project_is_cex", true);
      }

      if (filter.is_renounce && !filter.all) {
        query = query.eq("is_renounce", true);
      }

      if (filter.is_pro && !filter.all) {
        query = query.eq("is_pro", true);
      }

      query = query.not("degen_address", "is", null);

      // Add chainId filter based on the provided instructions
      if (!chainId) {
        query = query.eq("chain_id", "");
      } else if (chainId === 97) {
        query = query.eq("chain_id", 97);
      } else if (chainId === 1337) {
        query = query.eq("chain_id", 1337);
      } else if (chainId === 56) {
        query = query.in("chain_id", [56, 1, 137]);
      }

      query = query
        .order(filter.sortBy || "created_at", {
          ascending: filter.sortBy === "ASC",
        })
        .range((page - 1) * 12, page * 12 - 1);

      const { data: pools, error } = await query;

      if (error) {
        console.error(error);
      } else {
        const poolsWithThreadCount = pools.map((pool) => ({
          ...pool,
          thread_count: pool.thread.length,
        }));
        setPools(poolsWithThreadCount);
      }
    };

    fetchPools();
  }, [page, filter, chainId]);

  return (
    <>
      <Header />
      <Banner />
      <div className="bg-codgray-950 dark:bg-blue-ribbon-900">
        <div className="px-6 py-12 md:px-10 lg:px-20 space-y-10">
          <div className="flex flex-col md:flex-col lg:flex-row w-full gap-4 items-center justify-between">
            <input
              className="w-full border border-mid-gray-500 bg-mid-gray-900 text-white py-2 px-4 rounded-md"
              placeholder="Search Token by Name"
              onChange={(e) =>
                setFilter({
                  ...filter,
                  project_name: e.target.value,
                })
              }
            />

            <div className="flex items-center gap-4 w-full">
              <select
                name=""
                id=""
                className="w-full md:w-80 border border-mid-gray-500 bg-mid-gray-900 py-2 px-4 rounded-md placeholder:text-mid-gray-200 text-white"
                value={filter.marketcap}
                onChange={(e) =>
                  setFilter({ ...filter, marketcap: e.target.value })
                }
              >
                <option value="" disabled hidden>
                  Marketcap
                </option>
                <option value="launched_time">Launched Time</option>
                <option value="trading_volume">Trading Volume</option>
                <option value="24h_price_increase">24h Price Increase</option>
              </select>
              <select
                name=""
                id=""
                className="w-full md:w-80 border border-mid-gray-500 bg-mid-gray-900 py-2 px-4 rounded-md placeholder:text-mid-gray-200 text-white"
                value={filter.network}
                onChange={(e) =>
                  setFilter({ ...filter, network: e.target.value })
                }
              >
                <option value="" disabled hidden>
                  All Networks
                </option>
                <option value="">All Network</option>
                <option value="BSC">BSC</option>
                <option value="ETHEREUM">Ethereum</option>
                <option value="POLYGON">Polygon</option>
                <option value="BASE">Base</option>
                <option value="ARBITRUM">Arbitrum</option>
                <option value="AVALANCHE">Avalanche</option>
                <option value="FANTOM">Fantom</option>
              </select>
            </div>

            <div className="flex items-center gap-4 w-full">
              <select
                name=""
                id=""
                className="w-full md:w-80 border border-mid-gray-500 bg-mid-gray-900 py-2 px-4 rounded-md placeholder:text-mid-gray-200 text-white"
                value={filter.sortBy}
                onChange={(e) =>
                  setFilter({ ...filter, sortBy: e.target.value })
                }
              >
                <option value="" disabled hidden>
                  Sort By
                </option>
                <option value="ASC">Ascending</option>
                <option value="DESC">Descending</option>
              </select>

              <Listbox as="div" className="relative">
                <Listbox.Button className="w-full border border-mid-gray-500 bg-mid-gray-900 py-2 px-4 rounded-md text-white">
                  Badges
                </Listbox.Button>
                <Listbox.Options className="absolute w-80 -translate-x-full z-20 mt-2 border border-mid-gray-500 p-3 bg-mid-gray-800 rounded-md shadow-lg">
                  <Listbox.Option
                    value={filter.all}
                    onChange={() =>
                      setFilter({
                        ...filter,
                        all: !filter.all,
                      })
                    }
                  >
                    {({ active, selected }) => (
                      <div
                        className={`cursor-pointer flex gap-2 items-center select-none relative py-2 px-2 ${
                          active
                            ? "bg-blue-500 text-white"
                            : "text-white bg-mid-gray-800"
                        }`}
                      >
                        <input
                          type="checkbox"
                          defaultChecked={filter.all}
                          onChange={() =>
                            setFilter({
                              ...filter,
                              all: !filter.all,
                            })
                          }
                          className="bg-mid-gray-900 border border-mid-gray-500 rounded-sm"
                        />
                        All
                      </div>
                    )}
                  </Listbox.Option>
                  <Listbox.Option
                    value={filter.project_is_kyc}
                    onChange={() =>
                      setFilter({
                        ...filter,
                        project_is_kyc: !filter.project_is_kyc,
                      })
                    }
                  >
                    {({ active, selected }) => (
                      <div
                        className={`cursor-pointer flex gap-2 items-center select-none relative py-2 px-2 ${
                          active
                            ? "bg-blue-500 text-white"
                            : "text-white bg-mid-gray-800"
                        }`}
                      >
                        <input
                          type="checkbox"
                          defaultChecked={filter.project_is_kyc}
                          onChange={() =>
                            setFilter({
                              ...filter,
                              project_is_kyc: !filter.project_is_kyc,
                            })
                          }
                          className="bg-mid-gray-900 border border-mid-gray-500 rounded-sm"
                        />
                        Project is KYC
                      </div>
                    )}
                  </Listbox.Option>
                  <Listbox.Option
                    value={filter.project_is_audit}
                    onChange={() =>
                      setFilter({
                        ...filter,
                        project_is_audit: !filter.project_is_audit,
                      })
                    }
                  >
                    {({ active, selected }) => (
                      <div
                        className={`cursor-pointer flex gap-2 items-center select-none relative py-2 px-2 ${
                          active
                            ? "bg-blue-500 text-white"
                            : "text-white bg-mid-gray-800"
                        }`}
                      >
                        <input
                          type="checkbox"
                          defaultChecked={filter.project_is_audit}
                          onChange={() =>
                            setFilter({
                              ...filter,
                              project_is_audit: !filter.project_is_audit,
                            })
                          }
                          className="bg-mid-gray-900 border border-mid-gray-500 rounded-sm"
                        />
                        Project is Audit
                      </div>
                    )}
                  </Listbox.Option>
                  <Listbox.Option
                    value={filter.project_is_doxx}
                    onChange={() =>
                      setFilter({
                        ...filter,
                        project_is_doxx: !filter.project_is_doxx,
                      })
                    }
                  >
                    {({ active, selected }) => (
                      <div
                        className={`cursor-pointer flex gap-2 items-center select-none relative py-2 px-2 ${
                          active
                            ? "bg-blue-500 text-white"
                            : "text-white bg-mid-gray-800"
                        }`}
                      >
                        <input
                          type="checkbox"
                          defaultChecked={filter.project_is_doxx}
                          onChange={() =>
                            setFilter({
                              ...filter,
                              project_is_doxx: !filter.project_is_doxx,
                            })
                          }
                          className="bg-mid-gray-900 border border-mid-gray-500 rounded-sm"
                        />
                        Project is Doxx
                      </div>
                    )}
                  </Listbox.Option>
                  <Listbox.Option
                    value={filter.project_is_cex}
                    onChange={() =>
                      setFilter({
                        ...filter,
                        project_is_cex: !filter.project_is_cex,
                      })
                    }
                  >
                    {({ active, selected }) => (
                      <div
                        className={`cursor-pointer flex gap-2 items-center select-none relative py-2 px-2 ${
                          active
                            ? "bg-blue-500 text-white"
                            : "text-white bg-mid-gray-800"
                        }`}
                      >
                        <input
                          type="checkbox"
                          defaultChecked={filter.project_is_cex}
                          onChange={() =>
                            setFilter({
                              ...filter,
                              project_is_cex: !filter.project_is_cex,
                            })
                          }
                          className="bg-mid-gray-900 border border-mid-gray-500 rounded-sm"
                        />
                        Project is CEX
                      </div>
                    )}
                  </Listbox.Option>
                  <Listbox.Option
                    value={filter.is_renounce}
                    onChange={() =>
                      setFilter({ ...filter, is_renounce: !filter.is_renounce })
                    }
                  >
                    {({ active, selected }) => (
                      <div
                        className={`cursor-pointer flex gap-2 items-center select-none relative py-2 px-2 ${
                          active
                            ? "bg-blue-500 text-white"
                            : "text-white bg-mid-gray-800"
                        }`}
                      >
                        <input
                          type="checkbox"
                          defaultChecked={filter.is_renounce}
                          onChange={() =>
                            setFilter({
                              ...filter,
                              is_renounce: !filter.is_renounce,
                            })
                          }
                          className="bg-mid-gray-900 border border-mid-gray-500 rounded-sm"
                        />
                        Is Renounce
                      </div>
                    )}
                  </Listbox.Option>
                  <Listbox.Option
                    value={filter.is_pro}
                    onChange={() =>
                      setFilter({ ...filter, is_pro: !filter.is_pro })
                    }
                  >
                    {({ active, selected }) => (
                      <div
                        className={`cursor-pointer flex gap-2 items-center select-none relative py-2 px-2 ${
                          active
                            ? "bg-blue-500 text-white"
                            : "text-white bg-mid-gray-800"
                        }`}
                      >
                        <input
                          type="checkbox"
                          defaultChecked={filter.is_pro}
                          onChange={() =>
                            setFilter({ ...filter, is_pro: !filter.is_pro })
                          }
                          className="bg-mid-gray-900 border border-mid-gray-500 rounded-sm"
                        />
                        Is Pro
                      </div>
                    )}
                  </Listbox.Option>
                </Listbox.Options>
              </Listbox>
              <div
                className="p-3 flex items-center justify-center hover:bg-mid-gray-950 hover:border-mid-gray-400 border bg-mid-gray-900 rounded-md border-mid-gray-500 text-white"
                onClick={() => window.location.reload()}
              >
                <RiRefreshLine />
              </div>
            </div>
          </div>

          {pools.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {pools.map((pool) => (
                <Link
                  href={`/token/${pool.chain_id}/${pool.degen_address}`}
                  prefetch={false}
                  key={pool.id}
                  className="w-full"
                >
                  <div className="w-full">
                    <HoverBorderGradient
                      containerClassName="rounded-lg w-full"
                      as="button"
                    >
                      <div className="bg-mid-gray-900 w-12/12 border border-mid-gray-500 space-y-3 p-5 rounded-lg">
                        <div className=" absolute -mt-10 w-max">
                          <div className="flex items-center gap-2">
                            {pool?.project_percent_referral !== 0 && (
                              <div className="bg-yellow-500 text-black py-1 border border-mid-gray-500 text-sm ml-0 px-3 flex items-center justify-end rounded-md">
                                <marquee
                                  behavior=""
                                  className="h-5"
                                  direction="down"
                                  scrollamount="2"
                                >
                                  <div>Affiliate:</div>
                                  <div>{pool?.project_percent_referral}%</div>
                                </marquee>
                              </div>
                            )}
                            {pool.project_is_audit && (
                              <Link
                                href={pool.project_audit_url}
                                className="bg-cyan-500 text-white py-1 border border-mid-gray-500 text-sm px-3 flex items-center justify-end rounded-md"
                              >
                                Audit
                              </Link>
                            )}
                            {pool.project_is_kyc && (
                              <Link
                                href={pool.project_kyc_url}
                                className="bg-green-500 text-white py-1 border border-mid-gray-500 text-sm px-3 flex items-center justify-end rounded-md"
                              >
                                KYC
                              </Link>
                            )}
                            {pool.project_is_doxx && (
                              <Link
                                href={pool.project_doxx_url}
                                className="bg-orange-500 text-white py-1 border border-mid-gray-500 text-sm px-3 flex items-center justify-end rounded-md"
                              >
                                Doxx
                              </Link>
                            )}
                            {pool.is_pro && (
                              <div className="bg-gradient-to-r from-[#ecc440] via-[#fffa8a] to-[#ddac17] text-[#5F443A] font-semibold py-1 border border-mid-gray-500 text-sm px-3 flex items-center justify-end rounded-md">
                                PRO
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="space-y-5">
                          <div className="flex items-start gap-5 justify-start">
                            <Image
                              src={pool.project_logo}
                              width="54"
                              height="54"
                              className="rounded-full border border-mid-gray-500"
                              alt={pool.project_name}
                            />
                            <div className="absolute mt-8 ml-8 border border-mid-gray-400 bg-mid-gray-950 p-1 rounded-full">
                              <NetworkIcon network={pool.network} />
                            </div>

                            <div className="space-y-2 w-full">
                              <div className="flex justify-start items-center gap-2">
                                <h1 className="text-white font-semibold">
                                  {pool.project_name}
                                </h1>
                                <h2 className="text-mid-gray-300">
                                  {pool.project_symbol}
                                </h2>
                              </div>
                              <div className="flex w-full justify-between items-center">
                                <div className="flex justify-start gap-3 items-center">
                                  <a href={pool.twitter} className="text-white">
                                    <RiTwitterXLine />
                                  </a>
                                  <a href={pool.website} className="text-white">
                                    <RiGlobalLine />
                                  </a>
                                  <a
                                    href={pool.telegram}
                                    className="text-white"
                                  >
                                    <RiTelegramLine />
                                  </a>

                                  {(pool?.status === "Finished" ||
                                    pool?.status === "LP Lock Period") && (
                                    <Whisper
                                      trigger="click"
                                      placement={"bottom"}
                                      controlId={`control-id-bottom`}
                                      speaker={
                                        <Tooltip>
                                          This token already listing on Dex!
                                        </Tooltip>
                                      }
                                    >
                                      <Button appearance="subtle">
                                        <Image
                                          src="/images/background/listing-badge.webp"
                                          width="24"
                                          height="20"
                                          alt="Already Listing"
                                        />
                                      </Button>
                                    </Whisper>
                                  )}
                                </div>

                                <PriceChangeBadge
                                  symbol={pool?.project_symbol}
                                  hardcapToken={pool?.hardcap_token}
                                  poolId={pool?.id}
                                />
                              </div>
                            </div>
                          </div>

                          <p className="text-white text-start h-24 overflow-hidden">
                            {pool.project_description
                              ? readmore(pool.project_description, 100)
                              : "No Description"}
                          </p>

                          <div className="flex w-full justify-between items-center">
                            <div className="text-white flex items-center gap-1">
                              <RiTimeLine /> Created By:
                            </div>

                            <div className="text-blue-ribbon-500">
                              {truncate(pool.project_creator, 5, "...", 52)}
                            </div>
                          </div>

                          <div className="flex w-full justify-between items-center">
                            <div className="text-white flex items-center gap-1">
                              <RiDiscussLine /> Thread:
                            </div>

                            <div className="text-blue-ribbon-500">
                              {pool.thread_count}
                            </div>
                          </div>

                          <div className="flex w-full justify-start gap-2 items-center">
                            <div className="text-mid-gray-300">Marketcap:</div>
                            <div className="text-white">
                              ${Number(pool.marketcap).toFixed(2)}
                            </div>
                            <div className="text-mid-gray-300">
                              ({Number(pool.percentage_raise).toFixed(2)}%)
                            </div>
                          </div>

                          <div className="mt-2 h-3 relative w-full rounded-full">
                            <div className="w-full h-full bg-mid-gray-600 absolute rounded-full"></div>
                            <div
                              className="h-full bg-blue-ribbon-500 absolute rounded-full"
                              style={{ width: `${pool?.percentage_raise}%` }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </HoverBorderGradient>
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            <div className="bg-mid-gray-800 flex justify-center items-center flex-col gap-3 py-8 rounded-lg border border-mid-gray-500 px-4 text-white text-center w-full">
              No Pool Found, Let's go create new pools
              <Link
                href="/token/create"
                prefetch={false}
                className="bg-blue-500 rounded-md w-max text-white py-2 px-4"
              >
                Create New Pool
              </Link>
            </div>
          )}

          <div className="flex flex-wrap w-full gap-2 justify-end items-center">
            <div
              className="w-10 h-10 flex justify-center items-center rounded-lg p-1 bg-mid-gray-800 text-white border border-mid-gray-500"
              onClick={() => setPage(page > 1 ? page - 1 : 1)}
            >
              <RiArrowLeftLine />
            </div>
            <div className="w-10 h-10 flex justify-center items-center rounded-lg p-1 bg-mid-gray-800 text-white border border-mid-gray-500">
              {page}
            </div>
            <div
              className="w-10 h-10 flex justify-center items-center rounded-lg p-1 bg-mid-gray-800 text-white border border-mid-gray-500"
              onClick={() => setPage(page + 1)}
            >
              <RiArrowRightLine />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

// export async function getServerSideProps() {
//   const { data: pools, error } = await supabase.from("pool").select("*");

//   if (error) {
//     console.error(error);
//     return {
//       props: {
//         pools: [],
//       },
//     };
//   }

//   return {
//     props: {
//       pools,
//     },
//   };
// }
