import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import Link from "next/link";
import useDarkMode from "./useDarkMode";
import { Boxes } from "./ui/background-boxes";
import { FlipWords } from "./ui/flip-words";
import { createClient } from "@supabase/supabase-js";
import Image from "next/image";
import { truncate } from "@/libs/WebooLib";
import { readmore } from "@/utils";
import {
  RiGlobalLine,
  RiQuestionLine,
  RiTelegramLine,
  RiTwitterXLine,
} from "react-icons/ri";
import NetworkIcon from "./NetworkIcon";
import { HoverBorderGradient } from "./ui/hover-border-gradient";
import PriceChangeBadge from "./PriceChangeBadge";
import { Button, Tooltip, Whisper } from "rsuite";
import "rsuite/Tooltip/styles/index.css";

const supabaseUrl = process.env.NEXT_PUBLIC_SUPABASE_URL;
const supabaseKey = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

export function Banner() {
  const [colorTheme] = useDarkMode();
  const words = ["Secure", "Safe", "Fast"];
  const [topPool, setTopPool] = useState(null);

  useEffect(() => {
    const fetchTopPool = async () => {
      const { data: pool, error } = await supabase
        .from("pool")
        .select("*")
        .eq("status", "On Progress")
        .not("total_raised", "is", null)
        .neq("total_raised", 0)
        .order("total_raised", { ascending: false })
        .limit(1);

      if (error) {
        console.error(error);
      } else {
        setTopPool(pool[0]);
      }
    };

    fetchTopPool();
  }, []);

  return (
    <div className="relative w-full overflow-hidden bg-codgray-950 flex flex-col items-center justify-center">
      <div className="absolute inset-0 w-full h-full bg-codgray-950 z-20 [mask-image:radial-gradient(transparent,white)] pointer-events-none" />

      <Boxes />
      {topPool !== null ? (
        <div className="bg-codgray-950 dark:bg-blue-ribbon-900 w-full">
          <div className="px-6 py-8 lg:py-24 pt-20 md:pt-16 lg:pt-36 md:px-12 lg:px-20">
            <div className="flex flex-col md:flex-col lg:flex-row items-center justify-between">
              <div className="items-left text-left w-full py-12 flex flex-col">
                <div className="w-full">
                  <div className="-mt-10 md:mt-0 lg:mt-0">
                    <h1 className="relative z-20 my-6 font-bold tracking-wide whitespace-normal">
                      <div className="text-2xl md:text-3xl lg:text-5xl mx-auto leading-snug bg-clip-text text-transparent bg-gradient-to-t from-slate-200 to-slate-400 whitespace-normal md:whitespace-nowrap lg:whitespace-nowrap xl:whitespace-nowrap">
                        Easy Way Launch Token With <br /> Multi-Network &
                        <FlipWords words={words} />
                      </div>
                    </h1>
                    <h2 className="relative z-20 my-4 lg:my-8 text-white whitespace-normal dark:text-gray-500 md:whitespace-nowrap lg:whitespace-nowrap xl:whitespace-nowrap">
                      Easy Way Launch Token With Secure Support Multi-Network{" "}
                      <a
                        href="https://safupump.gitbook.io/safupump"
                        target="_blank"
                        className="text-blue-ribbon-400"
                      >
                        How's it works?
                      </a>
                    </h2>
                    <div className="relative z-20 flex flex-row justify-start items-center w-full gap-3 mt-4">
                      <Link
                        href="/token/create"
                        prefetch={false}
                        className="px-6 py-2.5 text-white font-semibold whitespace-nowrap bg-blue-ribbon-500 border border-blue-ribbon-500 rounded-md hover:bg-blue-ribbon-600"
                      >
                        Make Coin
                      </Link>
                      <a
                        href="https://safupump.gitbook.io/safupump"
                        target="_blank"
                        className="px-6 py-2.5 text-white dark:text-white dark:bg-gray-900 dark:border-gray-800 bg-mid-gray-900 border border-mid-gray-500 rounded-md hover:border-gray-600 hover:dark:border-gray-500"
                      >
                        Docs
                      </a>

                      <a
                        href="https://blocksafu.com/audit/0xCeE0aEF22cAAF24749184DFe0Ee5668eAD9Fd295"
                        target="_blank"
                        className="bg-white py-1 pl-1 pr-2 border border-mid-gray-400 rounded-md"
                      >
                        <Image
                          src="/images/background/Audited.png"
                          width="100"
                          height="40"
                          alt="Audited By BlockSAFU"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {topPool && (
                <div className="relative z-20 flex flex-col justify-center items-center w-full">
                  <div className="flex flex-col h-24">
                    <Image
                      src="/images/background/supernova.webp"
                      height={60}
                      width={60}
                      className="absolute -mt-2 -ml-6"
                      alt="Supernova"
                    />
                    <h3 className="text-white mt-5 flex justify-between items-center gap-4 w-max py-2 pl-12 pr-8 rounded-lg bg-gradient-to-r from-indigo-500 to-indigo-400 text-lg mb-6 font-semibold">
                      Supernova Boom
                      <Whisper
                        trigger="click"
                        placement={"bottom"}
                        controlId={`control-id-bottom`}
                        speaker={
                          <Tooltip>
                            The current meme with the most explosive potential
                            to supernova boom into a legendary bonding curve
                            phenomenon!. SafuPump is a decentralized platform
                            and does not endorse any meme coin. (DYOR)!
                          </Tooltip>
                        }
                      >
                        <Button appearance="subtle">
                          <RiQuestionLine />
                        </Button>
                      </Whisper>
                    </h3>
                  </div>
                  <Link
                    href={`/token/${topPool.chain_id}/${topPool.degen_address}`}
                    prefetch={false}
                    className="w-full"
                  >
                    <HoverBorderGradient
                      containerClassName="rounded-lg w-full text-left"
                      as="button"
                    >
                      <div className="bg-mid-gray-800 p-4 md:p-6 space-y-6 rounded-lg text-white">
                        <div className=" absolute -mt-10 w-max">
                          <div className="flex items-center gap-2">
                            {topPool?.project_percent_referral !== 0 && (
                              <div className="bg-yellow-500 text-black py-1 border border-mid-gray-500 text-sm ml-0 px-3 flex items-center justify-end rounded-md">
                                <marquee
                                  behavior=""
                                  className="h-5"
                                  direction="down"
                                  scrollamount="2"
                                >
                                  <div>Affiliate:</div>
                                  <div>
                                    {topPool?.project_percent_referral}%
                                  </div>
                                </marquee>
                              </div>
                            )}
                            {topPool.project_is_audit && (
                              <Link
                                href={topPool.project_audit_url}
                                className="bg-cyan-500 text-white py-1 border border-mid-gray-500 text-sm px-3 flex items-center justify-end rounded-md"
                              >
                                Audit
                              </Link>
                            )}
                            {topPool.project_is_kyc && (
                              <Link
                                href={topPool.project_kyc_url}
                                className="bg-green-500 text-white py-1 border border-mid-gray-500 text-sm px-3 flex items-center justify-end rounded-md"
                              >
                                KYC
                              </Link>
                            )}
                            {topPool.project_is_doxx && (
                              <Link
                                href={topPool.project_doxx_url}
                                className="bg-orange-500 text-white py-1 border border-mid-gray-500 text-sm px-3 flex items-center justify-end rounded-md"
                              >
                                Doxx
                              </Link>
                            )}
                            {topPool.is_pro && (
                              <div className="bg-gradient-to-r from-[#ecc440] via-[#fffa8a] to-[#ddac17] text-[#5F443A] font-semibold py-1 border border-mid-gray-500 text-sm px-3 flex items-center justify-end rounded-md">
                                PRO
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="flex items-start gap-4">
                          <Image
                            src={topPool.project_logo}
                            width="54"
                            height="54"
                            className="rounded-full border border-mid-gray-500"
                            alt={topPool.project_name}
                          />
                          <div className="space-y-2 w-full">
                            <div className="flex flex-col md:flex-row gap-2 justify-between w-full">
                              <div className="flex items-center gap-2">
                                <div className="font-light">Created By:</div>
                                <div className="text-blue-400">
                                  {truncate(
                                    topPool.project_creator,
                                    5,
                                    "...",
                                    52
                                  )}
                                </div>
                              </div>

                              <div className="flex justify-start gap-3 items-center">
                                <a
                                  href={topPool.twitter}
                                  className="text-white"
                                >
                                  <RiTwitterXLine />
                                </a>
                                <a
                                  href={topPool.website}
                                  className="text-white"
                                >
                                  <RiGlobalLine />
                                </a>
                                <a
                                  href={topPool.telegram}
                                  className="text-white"
                                >
                                  <RiTelegramLine />
                                </a>
                                <NetworkIcon network={topPool.network} />
                              </div>
                            </div>
                            <div className="flex items-center gap-2">
                              <div className="font-semibold">
                                {topPool.project_name}
                              </div>
                              <div className="text-mid-gray-300">
                                {topPool.project_symbol}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          {topPool.project_description
                            ? readmore(topPool.project_description, 100)
                            : "No Description"}
                        </div>

                        <div className="flex items-center justify-between">
                          <div className="flex w-full justify-start gap-2 items-center">
                            <div className="text-mid-gray-300">Marketcap:</div>
                            <div className="text-white">
                              ${Number(topPool.marketcap).toFixed(2)}
                            </div>
                            <div className="text-mid-gray-300">
                              ({Number(topPool.percentage_raise).toFixed(2)}%)
                            </div>
                          </div>

                          <PriceChangeBadge
                            symbol={topPool?.project_symbol}
                            hardcapToken={topPool?.hardcap_token}
                            poolId={topPool?.id}
                          />
                        </div>

                        <div className="mt-2 h-3 relative w-full rounded-full">
                          <div className="w-full h-full bg-mid-gray-600 absolute rounded-full"></div>
                          <div
                            className="h-full bg-blue-ribbon-500 absolute rounded-full"
                            style={{ width: `${topPool?.percentage_raise}%` }}
                          ></div>
                        </div>
                      </div>
                    </HoverBorderGradient>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-codgray-950 dark:bg-blue-ribbon-900">
          <div className="px-6 py-24 pt-32 md:px-16 lg:px-20">
            <div className="items-center text-center w-full pt-16 flex flex-col">
              <div className="w-full">
                <div className="-mt-10 md:mt-0 lg:mt-0">
                  <h1 className="relative z-20 my-6 font-bold tracking-wide whitespace-normal">
                    <div className="text-5xl mx-auto leading-snug bg-clip-text text-transparent bg-gradient-to-t from-slate-200 to-slate-400 whitespace-normal md:whitespace-nowrap lg:whitespace-nowrap xl:whitespace-nowrap">
                      Easy Way Launch Token With <br /> Multi-Network &
                      <FlipWords words={words} />
                    </div>
                  </h1>
                  <h2 className="relative z-20 my-8 text-white whitespace-normal dark:text-gray-500 md:whitespace-nowrap lg:whitespace-nowrap xl:whitespace-nowrap">
                    Easy Way Launch Token With Secure Support Multi-Network{" "}
                    <a
                      href="https://safupump.gitbook.io/safupump"
                      target="_blank"
                      className="text-blue-ribbon-400"
                    >
                      How's it works?
                    </a>
                  </h2>
                  <div className="relative z-20 flex flex-row justify-center items-center w-full gap-3 mt-4">
                    <Link
                      href="/token/create"
                      prefetch={false}
                      className="px-6 py-2.5 text-white font-semibold whitespace-nowrap bg-blue-ribbon-500 border border-blue-ribbon-500 rounded-md hover:bg-blue-ribbon-600"
                    >
                      Make Coin
                    </Link>
                    <a
                      href="https://safupump.gitbook.io/safupump"
                      target="_blank"
                      className="px-6 py-2.5 text-white dark:text-white dark:bg-gray-900 dark:border-gray-800 bg-mid-gray-900 border border-mid-gray-500 rounded-md hover:border-gray-600 hover:dark:border-gray-500"
                    >
                      Docs
                    </a>

                    <a
                      href="https://blocksafu.com/audit/0xCeE0aEF22cAAF24749184DFe0Ee5668eAD9Fd295"
                      target="_blank"
                    >
                      <Image
                        src="/images/background/Audited.png"
                        width="100"
                        height="40"
                        alt="Audited By BlockSAFU"
                      />
                    </a>
                  </div>
                  {topPool && (
                    <div className="relative z-20 mt-8 text-white">
                      <h3 className="text-2xl font-bold">Top Pool</h3>
                      <p>{topPool.project_name}</p>
                      <p>Percentage Raise: {topPool.percentage_raise}%</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
